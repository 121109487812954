import React, {useEffect, useRef} from "react"

const Headshot = ({
  mp4,
  webm,
  name,
  title
}) => {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
        return;
    }

    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
}, [refVideo]);

  return (
    <div className={`block text-center`}>
      <video ref={refVideo} className={`w-full h-auto mb-4`} loop autoPlay muted={true} playsInline>
        <source src={webm} type="video/webm"></source>
        <source src={mp4} type="video/mp4"></source>
      </video>
      <h3 className={`text-xs md:text-base font-bold`}>{name}</h3>
      <h4 className={`text-xs md:text-base `}>{title}</h4>
    </div>
    
  )
}

export default Headshot
