import * as React from "react"
import { Helmet } from "react-helmet"
import PrimaryButton from "../../components/Buttons/PrimaryButton"
import Headshot from "../../components/Headshot/Headshot"

import barbaramp4 from "../../images/team/Barbara.mp4"
import barbarawebm from "../../images/team/Barbara.webm"
import silhouttemp4 from "../../images/team/blokhaus_silhoutte.mp4"
import silhouttewebm from "../../images/team/blokhaus_silhoutte.webm"
import brennanmp4 from "../../images/team/Brennan.mp4"
import brennanwebm from "../../images/team/Brennan.webm"
import illanmp4 from "../../images/team/Illan.mp4"
import illanwebm from "../../images/team/Illan.webm"
import jennymp4 from "../../images/team/Jenny.mp4"
import jennywebm from "../../images/team/Jenny.webm"
import jennycarbonaromp4 from "../../images/team/JennyCarbonaro.mp4"
import jennycarbonarowebm from "../../images/team/JennyCarbonaro.webm"
import jordanmp4 from "../../images/team/Jordan.mp4"
import jordanwebm from "../../images/team/Jordan.webm"
import kristenmp4 from "../../images/team/Kristen.mp4"
import kristenwebm from "../../images/team/Kristen.webm"
import kylemp4 from "../../images/team/Kyle.mp4"
import kylewebm from "../../images/team/Kyle.webm"
import lelamp4 from "../../images/team/Lela.mp4"
import lelawebm from "../../images/team/Lela.webm"
import markmp4 from "../../images/team/Marc.mp4"
import markwebm from "../../images/team/Marc.webm"
import michaelmp4 from "../../images/team/Michael.mp4"
import michaelwebm from "../../images/team/Michael.webm"
import nicholasmp4 from "../../images/team/Nicholas.mp4"
import nicholaswebm from "../../images/team/Nicholas.webm"
import reidmp4 from "../../images/team/Reid.mp4"
import reidwebm from "../../images/team/Reid.webm"

const TeamPage = () => {
  const homepage_team = [
    {
      name : 'Ilan Ben-Brith',
      title : 'Head of Product, Web',
      webm : illanwebm,
      mp4 : illanmp4
    },
    {
      name : 'Brennan Caldwell',
      title : 'Web Developer & Software Engineer',
      webm : brennanwebm,
      mp4 : brennanmp4
    },
    {
      name : 'Jenny Carbonaro',
      title : 'Events Lead',
      webm : jennycarbonarowebm,
      mp4 : jennycarbonaromp4
    },
    {
      name : 'Barbara Curry',
      title : 'Sr Project Manager',
      webm : barbarawebm,
      mp4 : barbaramp4
    },
    {
      name : 'Kristen DiVenti',
      title : 'Executive Assistant',
      webm : kristenwebm,
      mp4 : kristenmp4
    },
    {
      name : 'Nick Garcia',
      title : 'Specialist, Social Media',
      webm : nicholaswebm,
      mp4 : nicholasmp4
    },
    {
      name : 'Michael Holey',
      title : 'Community Manager',
      webm : michaelwebm,
      mp4 : michaelmp4
    },
    {
      name : 'Kyle Kelley',
      title : 'Web Developer',
      webm : kylewebm,
      mp4 : kylemp4
    },
    {
      name : 'Lela Maloney',
      title : 'Graphic Designer',
      webm : lelawebm,
      mp4 : lelamp4
    },
    {
      name : 'Jenny Mauric',
      title : 'Creative Director',
      webm : jennymp4,
      mp4 : jennywebm
    },
    {
      name : 'Mark Soares',
      title : 'Founder',
      webm : markwebm,
      mp4 : markmp4
    },
    {
      name : 'Jordan Wood',
      title : 'Senior Strategic Communications Manager',
      webm : jordanwebm,
      mp4 : jordanmp4
    },
    {
      name : 'Reid Yager',
      title : 'Global Head of Communications & PR',
      webm : reidwebm,
      mp4 : reidmp4
    }
  ]

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blokhaus - Team</title>
      </Helmet>
      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block w-full pt-10 pb-6 px-6 md:pt-24 md:pb-24 mx-auto text-center md:text-left">
          <div className={`w-full md:w-9/12 mx-auto mb-10 md:mb-0`}>
            <h1 className={`h2 text-left md:text-center mb-8`}>Whether you have a seed of an idea or are curious about the dynamic world of blockchain, we can help. Let’s build something together.</h1>
            <PrimaryButton 
              text={`Get in touch`}
              url={`/contact`}
              className={'mx-auto'}
            />
          </div>
        </div>
      </div>

      <div className={`text-black md:pb-8`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 pt-0 px-6 mx-auto mb-0 md:mb-2 text-center md:text-left">
          {homepage_team.map((member, index) => {
            return (
              <Headshot 
                name={member.name}
                title={member.title}
                webm={member.webm}
                mp4={member.mp4}
                key={index}
              />
            )
          })}
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative grid grid-cols-2 md:flex md:gap-12 md:items-center lg:gap-24 pt-0 pb-1 px-6 md:pt-0 md:pb-20 mx-auto text-center md:text-left">
          <div className={`block md:w-6/12 lg:w-3/12`}>
            <Headshot 
              mp4={silhouttemp4}
              webm={silhouttewebm}
            />
          </div>
          <div className={`block md:hidden`}></div>
          <div className={`hidden md:block w-full md:w-6/12 lg:w-9/12`}>
            <h2 className={`h2 md:mb-11`}>We are a team of creators, thinkers, doers and blockchain believers focused on impact.</h2>
            <PrimaryButton 
              text={`Explore Careers`}
              url={`/careers`}
            />
          </div>
        </div>
        <div style={{ maxWidth: 1600 }} className="z-10 relative flex md:hidden pt-0 pb-16 px-6 ">
          <div className={`block w-full`}>
            <h2 className={`h2 mb-8`}>We are a team of creators, thinkers, doers and blockchain believers focused on impact.</h2>
            <PrimaryButton 
              text={`Explore Careers`}
              url={`/careers`}
              invert
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamPage
